import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import moment from "moment";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { connect } from "react-redux";
import { Button, ModalDelete } from "expano-components";
import {
  PanelLoader,
  Main,
  Dashboard,
  InputField,
  CheckField,
  DatePickerField,
} from "components";
import { getUser, deleteUser, updateUser } from "actions/users";

import { ReactComponent as InfoIcon } from "icons/info.svg";
import { ReactComponent as UserIcon } from "icons/user.svg";

const UserEdit = ({
  match: {
    params: { id },
  },
  handleSubmit,
  user,
  pristine,
  history,
  getUser,
  updateUser,
  change,
}) => {
  const [is_open_modal, handleOpenModal] = useState(false);

  useEffect(() => {
    getUser(id);
  }, [id]);

  const onSubmit = (values) => {
    if (values.birth_date) {
      values.birth_date = moment(values.birth_date).format("YYYY-MM-DD");
    }
    return updateUser(id, values).catch((err) => {
      throw new SubmissionError(err);
    });
  };

  const handleDelete = async () => {
    try {
      await deleteUser(id);
      history.push("/users");
    } catch (error) {}
  };

  if (["invalid", "loading"].includes(user.status)) {
    return <PanelLoader />;
  }

  if (user.status === "failure") {
    return <Redirect to="/users" />;
  }
  return (
    <Main
      page={{
        breadcrumbs: [
          { name: "Użytkownicy", path: "/users" },
          {
            name: user.data?.full_name,
          },
        ],
        name: user?.data?.full_name,
        icon: <UserIcon />,
        buttons: (
          <Button
            type="delete"
            text="Usuń użytkownika"
            onClick={() => handleOpenModal(true)}
          />
        ),
      }}
    >
      {is_open_modal && (
        <ModalDelete
          handleDelete={handleDelete}
          handleClose={() => handleOpenModal(false)}
          name={`użytkownika ${user.data.email}`}
        />
      )}
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <Dashboard
            header={{
              icon: <InfoIcon />,
              title: "Podstawowe dane",
            }}
            footer={
              <Button
                disabled={pristine}
                type="save"
                onClick={handleSubmit(onSubmit)}
              />
            }
          >
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <div className="dashboard__form__submit">
                <Field
                  name="is_active"
                  label="Aktywny"
                  component={CheckField}
                />
                <Field
                  name="email"
                  label="Adres e-mail"
                  component={InputField}
                  type="text"
                />
                <Field
                  name="first_name"
                  label="Imię"
                  component={InputField}
                  type="text"
                />
                <Field
                  name="last_name"
                  label="Nazwisko"
                  component={InputField}
                  type="text"
                />
                <DatePickerField
                  name="birth_date"
                  label="Data urodzenia"
                  change={change}
                  init_value={user?.data?.birth_date}
                />
                <Field
                  name="password"
                  label="Hasło"
                  component={InputField}
                  type="password"
                />
                <Field
                  name="password_confirmation"
                  label="Powtórz hasło"
                  component={InputField}
                  type="password"
                />
              </div>
            </form>
          </Dashboard>
        </div>
      </div>
    </Main>
  );
};

const mapStateToProps = ({ user }) => ({
  user,
  initialValues: {
    is_active: user.data?.is_active,
    email: user.data?.email,
    first_name: user.data?.first_name,
    last_name: user.data?.last_name,
    birth_date: user.data?.birth_date,
  },
});
const mapDispatchToProps = (dispatch) => ({
  getUser: (id) => dispatch(getUser(id)),
  updateUser: (id, values) => dispatch(updateUser(id, values)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "edit-user",
    enableReinitialize: true,
  })(UserEdit)
);
