import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Button, MainTable, Main, Dashboard } from "components";
import { parseQuery } from "utils/api";
import { getUsers, filterUsers, toggleFilters } from "actions/users";

import { ReactComponent as UserIcon } from "icons/user.svg";

const UsersList = ({
  users,
  toggleFilters,
  getUsers,
  filterUsers,
  history,
  location: { search },
}) => {
  const query = parseQuery(search);
  const [is_loaded, setLoaded] = useState(false);

  const sort = {
    "sort[column]": query["sort[column]"] || "id",
    "sort[order]": query["sort[order]"] || "asc",
  };

  useEffect(() => {
    getUsers({ ...query, ...sort });
    setLoaded(true);
  }, [search]);

  useEffect(() => {
    if (is_loaded) {
      getUsers({ ...query, page: undefined });
    }
  }, [users.filters.query]);

  const { data, meta } = users;

  return (
    <Main
      page={{
        breadcrumbs: [{ name: "Użytkownicy" }],
        name: "Użytkownicy",
        icon: <UserIcon />,
        buttons: (
          <Button type="add" text="Dodaj użytkownika" path="/users/new" />
        ),
      }}
    >
      <Dashboard>
        <MainTable
          is_loading={["invalid", "loading"].includes(users.status)}
          is_searching={users.status === "searching"}
          filters_open={users.filters.open}
          toggleFilters={toggleFilters}
          data={data}
          meta={meta}
          sort={sort}
          head={[
            { label: "E-mail", name: "email" },
            { label: "Imię i nazwisko", name: "last_name" },
            { label: "Data urodzenia", name: "birth_date" },
            { label: "Aktywny", name: "is_active" },
          ]}
          empty_text="Brak użytkowników"
          renderRow={({ id, email, full_name, is_active, birth_date }) => (
            <tr
              key={id}
              style={{
                cursor: "pointer",
                backgroundColor: is_active
                  ? "transparent"
                  : "rgba(228, 27, 27, 0.2)",
              }}
              onMouseDown={(event) => {
                if (event.button === 1) {
                  window.open(`/users/${id}`, "_blank");
                }
              }}
              onClick={() => history.push(`/users/${id}`)}
            >
              <td>{email}</td>
              <td>{full_name}</td>
              <td>
                {birth_date ? moment(birth_date).format("DD.MM.YYYY") : "-"}
              </td>
              <td>
                <div
                  className={`product_status ${
                    is_active ? "success" : "danger"
                  }`}
                >
                  <span>{is_active ? "Aktywny" : "Nieaktywny"}</span>
                </div>
              </td>
            </tr>
          )}
          filtersAction={filterUsers}
          filters={[
            {
              type: "input",
              label: "e-mail",
              name: "email",
              search_type: "matches",
            },
            {
              type: "input",
              label: "Imię i nazwisko",
              name: "first_name_or_last_name",
              search_type: "matches",
            },
          ]}
        />
      </Dashboard>
    </Main>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getUsers: (data) => dispatch(getUsers(data)),
  filterUsers: (data) => dispatch(filterUsers(data)),
  toggleFilters: () => dispatch(toggleFilters()),
});
export default connect(
  ({ users }) => ({ users }),
  mapDispatchToProps
)(UsersList);
